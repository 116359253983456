@import '../../../styles/stylingVariables.scss';

.qr-creator-container {

  .qr-top-actions {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    .creator-filters {
      width: 90%;
      display: flex;
      gap: 30px;
      align-items: center;

      .styled-input-icon {
        gap: 0;
      }

      .qr-search {
        width: 250px;
        gap: 0;
      }

      .creator-dropdown {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }

    }
  }

  .qr-locations-container {
    overflow-y: auto;
    margin-top: 15px;
    padding-top: 10px;
    border-top: 3px solid $neutrals-50;
    // &>.qr-location~.qr-location {
    //   border-top: 1px solid $neutrals-50;
    // }

    .qr-location {
      display: flex;
      flex-direction: column;

      .qr-location-header {
        align-items: center;
        padding: 0 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid $neutrals-50;

        .typography {
          align-items: center;
          gap: 5px;
          font-size: 16px;
        }
      }

      .qr-card-headers {
        padding: 0 10px;
        display: flex;
        flex-direction: row;

        .qr-header-descriptors {
          display: flex;
          flex-direction: row;
          width: calc(100% - 170px);
          text-align: left;

          .qr-header-name,
          .qr-header-company {
            width: 40%;
          }

          .qr-header-name {
            .typography {
              color: $primary-90;
              font-weight: 500;
            }
          }
        }

        .location-card-actions {
          width: 170px;

          .typography {

            color: $secondary-90;
            width: 100%;
            display: flex;
            justify-content: flex-end;
          }
        }
      }

      .qr-card-none {
        font-style: italic;
        color: $neutrals-50;
        padding: 15px 10px;
      }

      .qr-cards {
        display: flex;
        margin-bottom: 10px;
        flex-direction: column;
        gap: 10px;

        .qr-card {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 0 10px;

          .qr-card-descriptors {
            width: calc(100% - 170px);
            font-size: 14px;
            display: flex;
            flex-direction: row;
            text-align: left;

            .qr-card-name,
            .qr-card-description {
              width: 40%;
            }

            .qr-card-name {
              color: $primary-90;
              font-weight: 500;
            }
          }

          .qr-card-actions {
            width: 170px;
            display: flex;
            flex-direction: row;
            gap: 10px;
          }
        }
      }
    }
  }
}