@import '../../styles/stylingVariables.scss';

.companies-container {

  .companies-cards {
    border-top: 3px solid $neutrals-50;
    display: flex;
    margin: 20px 0;
    padding: 20px 0;
    gap: 20px;
    flex-wrap: wrap;

    .add-new-company {
      gap: 40px;

      svg {
        width: 70px;
        height: 70px;
      }
    }

    .company-card {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 0;

      .top-card {
        width: 100%;
        height: 240px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;
        background-color: $primary-90;
        background-image: url(../../pics/company.PNG);
        background-position: center;
        background-size: cover;
        padding: 10px;
      }

      .bot-card {
        height: 80px;
        width: 100%;
        padding: 20px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 20px;
        font-size: 14px;
      }
    }
  }
}